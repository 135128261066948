/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'animated_alert_2': {
    width: 16,
    height: 16,
    viewBox: '0 0 18 21.5',
    data: '<g class="ldl-scale" style="transform-origin:50% 50%"><g class="ldl-ani" style="transform-origin:9px 10.75px;animation:1s linear 0s infinite normal forwards running breath-89a950b5-5576-431b-b4cf-041962f70d85"><g class="ldl-layer"><g class="ldl-ani" transform="scale(.2275)"><path pid="0" d="M7 19.5c0 1.1.9 2 2 2s2-.9 2-2H7zM9 0c-.8 0-1.5.7-1.5 1.5v1.2C4.3 3.4 2 6.2 2 9.5v6l-2 2v1h18v-1l-2-2v-6c0-3.3-2.3-6.1-5.5-6.8V1.5C10.5.7 9.8 0 9 0z" id="add_alert-24px_1_" fill="#be3939"/></g></g></g></g>',
    lastMod: '1728370415091.6458'
  }
})
