/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'gps': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M10 6.727A3.273 3.273 0 1013.273 10 3.272 3.272 0 0010 6.727zm7.315 2.455a7.359 7.359 0 00-6.5-6.5V1H9.182v1.685a7.359 7.359 0 00-6.5 6.5H1v1.636h1.685a7.359 7.359 0 006.5 6.5V19h1.636v-1.685a7.359 7.359 0 006.5-6.5H19V9.182zM10 15.727A5.727 5.727 0 1115.727 10 5.723 5.723 0 0110 15.727z" fill="#1a1a1a"/>',
    lastMod: '1728370415095.6458'
  }
})
