/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'warning-form-field': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<g transform="translate(-275 -298)"><circle pid="0" cx="7.5" cy="7.5" r="7.5" transform="translate(275 298)" fill="#be3a39"/><path pid="1" fill="none" stroke="#fff" stroke-linecap="square" stroke-width="1.5" d="M282.5 302.5v3M282.5 308.5h0"/></g>',
    lastMod: '1728370415095.6458'
  }
})
