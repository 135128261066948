/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'schedule-type': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0z" fill="none"/><path pid="1" d="M19 3h-1V1h-2v2H8V1H6v2H5a1.991 1.991 0 00-1.99 2L3 19a2 2 0 002 2h14a2.006 2.006 0 002-2V5a2.006 2.006 0 00-2-2zm0 16H5V5h14z" fill="#009bd4"/><path pid="2" d="M19.792 17.27l-3.818-3.72a4.048 4.048 0 00-.946-4.35 4.169 4.169 0 00-4.667-.82l2.712 2.71-1.892 1.892-2.775-2.712a4.064 4.064 0 00.82 4.667 4.048 4.048 0 004.351.946l3.819 3.717a1.822 1.822 0 002.271-.185 1.708 1.708 0 00.125-2.149z" fill="#009bd4"/>',
    lastMod: '1728370415095.6458'
  }
})
