/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'subscription': {
    width: 16,
    height: 16,
    viewBox: '0 0 25 24',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><g data-name="Group 2074"><path pid="0" d="M16.49 15.87a.61.61 0 00-.09-.18l-.12-.14a1.21 1.21 0 00-.32-.22 1.09 1.09 0 00-.48-.11 1.13 1.13 0 00-1.13 1.14 1.15 1.15 0 00.08.41 1.64 1.64 0 00.16.29 1.13 1.13 0 00.64.37h.21a1.13 1.13 0 001.13-1.13 1 1 0 00-.08-.43zM0 0v24h24V0zm11 22a6.7 6.7 0 01-.82-2 7 7 0 01-.2-2 7.26 7.26 0 015.43-6.72 7 7 0 012.1-.23 6.72 6.72 0 012 .35 7.1 7.1 0 012 1v2.8a4.54 4.54 0 00-.39-.48 5.15 5.15 0 00-1.61-1.18 5.08 5.08 0 00-2-.51h-.21A5.27 5.27 0 0012 18a5.33 5.33 0 001.39 3.77l.15.15z" fill="none"/><g data-name="Group 2075" fill="#009bd4"><path pid="1" data-name="Path 6716" d="M20.5 2h-17a1 1 0 00-1 1v18a1 1 0 001 1H11a6.7 6.7 0 01-.82-2H4.5V4h15v7.32a7.1 7.1 0 012 1V3a1 1 0 00-1-1z"/><path pid="2" data-name="Path 6717" d="M8.45 17.44a1.14 1.14 0 10-1.13-1.13 1.13 1.13 0 001.13 1.13z"/><path pid="3" data-name="Path 6719" d="M15.44 8A3.8 3.8 0 008.2 9.66a4 4 0 00.36 1.6 3.8 3.8 0 003.29 2.17 7.18 7.18 0 013.59-2.19 3.71 3.71 0 00.35-1.6A3.89 3.89 0 0015.44 8zm-2.57 3.5a2 2 0 01-1.75-3.7 2 2 0 011.74 3.7z"/></g></g><path pid="4" d="M19.7 21.72a4.25 4.25 0 111.8-3.3" stroke="#009bd4" stroke-miterlimit="10" stroke-width="2" fill="none"/><path pid="5" fill="#009bd4" d="M21.5 20.83l-2.65-2.92h5.3l-2.65 2.92z"/></g></g>',
    lastMod: '1728370415095.6458'
  }
})
