/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'warning-last-measurement': {
    width: 13,
    height: 13,
    viewBox: '0 0 13 13',
    data: '<g transform="translate(-275 -298)"><circle pid="0" cx="6.5" cy="6.5" r="6.5" transform="translate(275 298)" fill="#be3a39"/><path pid="1" fill="none" stroke="#fff" stroke-linecap="square" stroke-width="1.5" d="M281.5 301.5v3M281.5 307.5h0"/></g>',
    lastMod: '1728370415095.6458'
  }
})
