/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'button-delete': {
    width: 16,
    height: 16,
    viewBox: '0 0 9 12',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M.5 5.5h8v4.27a1.73 1.73 0 01-1.73 1.73H2.23A1.73 1.73 0 01.5 9.77V5.5h0zM3.5.5h2a3 3 0 013 3v0h0-8 0v0a3 3 0 013-3zM2.5 7.5v2M6.5 7.5v2M4.5 7.5v2"/></g></g>',
    lastMod: '1728370415091.6458'
  }
})
