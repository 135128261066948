/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'power-battery-discharging': {
    width: 16,
    height: 16,
    viewBox: '0 0 20 20',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><path pid="0" data-name="Path 6883" d="M18.62 18.24v-2.38h1.07v-3.33h-1.07v-2.42A1.11 1.11 0 0017.52 9H1.12A1.11 1.11 0 000 10.11v8.13a1.11 1.11 0 001.11 1.11h16.4a1.11 1.11 0 001.11-1.11zM1.47 18a.11.11 0 01-.11-.11v-7.43a.12.12 0 01.12-.1h15.21a.12.12 0 01.11.1v7.38a.13.13 0 01-.11.11z" fill="#8a1818"/><path pid="1" data-name="Rectangle 2680" fill="#8a1818" d="M2.66 11.68h1.5v5h-1.5zM5.49 11.68h1.5v5h-1.5zM8.31 11.68h1.5v5h-1.5zM11.14 11.68h1.5v5h-1.5z"/><path pid="2" fill="none" d="M0 0h20v20H0z"/><circle pid="3" cx="14.5" cy="5.5" r="5.5" fill="#8a1818"/><path pid="4" d="M13.54 8.4l.94-2.29a.16.16 0 00-.14-.21h-1.66a.14.14 0 01-.11-.23l2.64-3.22a.15.15 0 01.25.15l-.94 2.29a.16.16 0 00.14.21h1.66a.14.14 0 01.11.23l-2.64 3.22a.15.15 0 01-.25-.15z" fill="#fff" stroke="#fff" stroke-miterlimit="10" stroke-width=".38"/></g></g>',
    lastMod: '1728370415095.6458'
  }
})
