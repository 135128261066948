/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'radio-0': {
    width: 23.17,
    height: 16,
    viewBox: '0 0 23.17 16',
    data: '<g fill="#ccc"><path pid="0" d="M6.1 8.14h1.221a1.222 1.222 0 011.22 1.221v5.417a1.222 1.222 0 01-1.22 1.221H6.1a1.221 1.221 0 01-1.221-1.221V9.361A1.221 1.221 0 016.1 8.14zM1.22 9.506h1.221a1.222 1.222 0 011.22 1.221v4.052A1.222 1.222 0 012.441 16H1.22a1.221 1.221 0 01-1.221-1.221v-4.052A1.221 1.221 0 011.22 9.506zM10.976 5.698h1.221a1.222 1.222 0 011.22 1.221v7.86A1.222 1.222 0 0112.197 16h-1.221a1.221 1.221 0 01-1.221-1.221v-7.86a1.221 1.221 0 011.221-1.221zM15.847 2.849h1.221a1.222 1.222 0 011.22 1.221v10.709A1.221 1.221 0 0117.067 16h-1.22a1.221 1.221 0 01-1.221-1.221V4.07a1.221 1.221 0 011.221-1.221zM20.727 0h1.221a1.222 1.222 0 011.22 1.221v13.561a1.221 1.221 0 01-1.221 1.221h-1.22a1.221 1.221 0 01-1.221-1.221V1.221A1.221 1.221 0 0120.727 0z"/></g>',
    lastMod: '1728370415095.6458'
  }
})
