/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'menu-mobile-show': {
    width: 21,
    height: 17,
    viewBox: '0 0 21 17',
    data: '<g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M.5.5h19.639M.5 16.5h19.639M.5 8.5h20"/></g>',
    lastMod: '1728370415095.6458'
  }
})
