/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'recording': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M15.892 4.109l-1.175 1.175a6.674 6.674 0 11-5.55-1.892v1.683a5 5 0 104.367 1.392l-1.175 1.175a3.342 3.342 0 11-3.192-.858v1.783A1.65 1.65 0 008.334 10a1.667 1.667 0 003.333 0 1.64 1.64 0 00-.833-1.433v-6.9h-.833a8.336 8.336 0 105.892 2.442z" fill="#009bd4"/>',
    lastMod: '1728370415095.6458'
  }
})
