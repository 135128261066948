/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'clock': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill="none" d="M0 0h20v20H0z"/><path pid="1" d="M10 2a8 8 0 108 8 8.024 8.024 0 00-8-8zm0 14.4a6.4 6.4 0 116.4-6.4 6.408 6.408 0 01-6.4 6.4zM10.4 6H9.2v4.8l4.16 2.56.64-1.04-3.6-2.16z" fill="#1a1a1a"/>',
    lastMod: '1728370415091.6458'
  }
})
