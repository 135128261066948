/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'audit': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0z" fill="none"/><path pid="1" d="M18.667 17.684a1.665 1.665 0 001.659-1.667V5.666a1.672 1.672 0 00-1.667-1.667H5.326a1.672 1.672 0 00-1.667 1.667l.008 10.351a1.672 1.672 0 001.667 1.667H2v1.667h20v-1.667zM5.326 5.667h13.333l.008 10.351H5.334z" fill="#009bd4"/><path pid="2" d="M19.792 15.484l-3.494-3.405a3.7 3.7 0 00-.866-3.979 3.816 3.816 0 00-4.271-.75l2.483 2.478-1.733 1.732-2.539-2.482a3.72 3.72 0 00.75 4.271 3.7 3.7 0 003.983.866l3.494 3.405c.231.231 1.033-.085 1.721-.839s.7-1.124.472-1.3z" fill="#009bd4"/>',
    lastMod: '1728370415091.6458'
  }
})
