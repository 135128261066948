/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'button-edit': {
    width: 16,
    height: 16,
    viewBox: '0 0 12 12',
    data: '<g data-name="Layer 2"><path pid="0" d="M1.56 7.45L.5 11.34l3.89-1.06 1-1 5.32-5.32a2 2 0 000-2.83 2 2 0 00-2.83 0L2.55 6.46z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" data-name="Layer 1"/></g>',
    lastMod: '1728370415091.6458'
  }
})
