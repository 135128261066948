/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'date-range': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0z" fill="none"/><path pid="1" d="M7 11h2v2H7zm14-5v14a2.006 2.006 0 01-2 2H5a2 2 0 01-2-2l.01-14A1.991 1.991 0 015 4h1V2h2v2h8V2h2v2h1a2.006 2.006 0 012 2zM5 8h14V6H5zm14 12V10H5v10zm-4-7h2v-2h-2zm-4 0h2v-2h-2z" fill="#0089ba"/>',
    lastMod: '1728370415091.6458'
  }
})
